<template>
  <common-evaluation
    :is-full-time="true"
  />
</template>

<script>
  import CommonEvaluation from './CommonEvaluation'

  export default {
    name: 'EvaluationFulltime',
    components: {
      'common-evaluation': CommonEvaluation,
    },
    data () {
      return {
        items: [],
      }
    },
    computed: {},
    watch: {},
    created () {
    },
    mounted () {},
    methods: {},
  }
</script>
<style scoped lang="sass">
</style>
